<style lang="scss" scoped>
.bill {
	background-color: #f2f3f5;
}

.goods_name {
	font-size: 0.22rem;
	color: $font_color_sec;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.all_assured_btn {
	color: $color_main;
}
.bill_item .seq {
	color: #969799;
}
.goods_info {
	display: flex;
	align-items: center;
	.seq {
		width: 0.6rem;
	}
	.assureds {
		width: calc(100% - 0.6rem - 1.2rem - 1.2rem);
		padding: 0 0.1rem;
		box-sizing: border-box;
	}
	.money {
		width: 1.2rem;
		padding: 0.04rem;
		box-sizing: border-box;
		text-align: center;
	}
	.state {
		width: 1.2rem;
		text-align: right;
	}
	.assureds_header {
		text-align: center;
	}
	.state_success {
		color: $success;
	}
	.state_error {
		color: $danger;
	}
}
.empty {
	background-color: #fff;
}

.text_center {
	text-align: center;
}

.assured_row {
	display: flex;
	align-items: center;
	.name {
		width: 30vw;
		text-align: left;
	}
}

::v-deep .van-dialog__content {
	max-height: 70vh;
	overflow: auto;
}

.p_file {
	width: 1.6rem;
	padding: 0 0.1rem;
	text-align: right;
}
.overlay {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.pfile_row {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
</style>

<template>
	<div class="bill">
		<header-nav>订单列表</header-nav>
		<van-overlay :show="httpLoading">
			<div class="overlay">
				<van-loading />
			</div>
		</van-overlay>

		<van-list v-model="loading" :finished="finished" :finished-text="list.length ? '没有更多了' : ''" @load="onLoad">
			<van-cell v-for="(item, index) in list" :key="index">
				<div class="bill_item">
					<div class="goods_name">
						<span v-text="`${item.proName} - ${item.comboName}`"></span>
						<span v-text="item.createTime"></span>
					</div>
					<div class="goods_info">
						<span class="seq" v-text="index + 1"></span>
						<span class="assureds">
							<span class="assured_name" v-text="assuredNameFormatter(item)"></span>
							<span class="all_assured_btn" @click="showAllAssured(item)" v-text="` (${item.assureNum > showNameNum ? '等' : ''}${item.assureNum}人)`"></span>
						</span>
						<span class="money" v-text="item.premium"></span>
						<span class="state" v-if="item.payState === 1" :class="{ state_success: item.billState == 4 }" v-text="billStateDict[String(item.billState)]"></span>
						<span class="state" v-else :class="{ state_error: item.payState == 0 }" v-text="payStateDict[String(item.payState)]"></span>
						<span class="p_file">
							<van-button v-if="item.billState == 4" type="info" @click="pFile(item)">保单</van-button>
						</span>
					</div>
				</div>
			</van-cell>
		</van-list>
		<van-empty v-show="!list.length" class="empty" description="暂无订单" />

		<!-- 被保险人弹窗 -->
		<van-dialog v-model="assuredDialog" className="assured_dialog" title="被保险人" confirmButtonText="关闭" confirmButtonColor="#2594EF">
			<van-cell v-for="(as, index) in assuredList" :key="index" value-class="text_center">
				<div class="assured_row">
					<span class="name" v-text="as.name"></span>
					<span class="mobile" v-text="as.mobile"></span>
				</div>
			</van-cell>
		</van-dialog>

		<!-- 保单下载弹窗 -->
		<van-dialog v-model="pFileDialog" className="assured_dialog" title="保单下载" confirmButtonText="关闭" confirmButtonColor="#2594EF">
			<van-cell v-for="(item, index) in pFileList" :key="index" value-class="text_center">
				<div class="pfile_row">
					<span class="name" v-text="item.name"></span>
					<span class="btn">
						<van-button type="info" v-if="item.url" @click="downloadPFile(item.url)">下载</van-button>
					</span>
				</div>
			</van-cell>
		</van-dialog>
		<page-footer></page-footer>
		<fileDownload :show.sync="show" :url="fileUrl" @update:show="val => (show = val)" name="保单" />
	</div>
</template>

<script>
import { Cell, Loading, List, Empty, Dialog, Button, Overlay } from 'vant';
import { http_getBills } from '@/request/billSearch';
import { getPFileInfoById } from '@/request/api';
import { payStateDict, billState } from '@/config/fixedParams';
import headerNav from './components/headerNav.vue';
import fileDownload from '@/components/fileDownload.vue';

export default {
	name: 'bill',
	components: {
		[Loading.name]: Loading,
		[Overlay.name]: Overlay,
		[List.name]: List,
		[Cell.name]: Cell,
		[Empty.name]: Empty,
		[Button.name]: Button,
		headerNav,
		[Dialog.Component.name]: Dialog.Component,
		fileDownload,
	},
	data() {
		return {
			list: [],
			loading: false,
			finished: false,
			total: 0,
			currentPage: -1,
			showNameNum: 6, // 要显示的被保险人名字的数量
			payStateDict: payStateDict,
			billStateDict: billState,

			assuredDialog: false,
			assuredList: [],

			start: '',
			end: '',

			// 保单下载
			httpLoading: false,
			pFileDialog: false,
			pFileList: [],
			show: false,
			fileUrl: '',
		};
	},
	created() {
		this.setSearchDate();
		// this.search()
	},
	methods: {
		back() {
			this.$router.go(-1);
		},

		// 保单
		pFile(info) {
			console.log(info);
			this.httpLoading = true;
			getPFileInfoById(info.billId).then(res => {
				this.httpLoading = false;
				if (res.insureType === 1) {
					// 每人一单
					this.pFileList = res.assuredPolicyVoList;
				} else {
					this.pFileList = [{ name: '保单', url: res.url }];
				}
				this.pFileDialog = true;
			});
		},

		// 保单下载
		downloadPFile(url) {
			// window.open(url, '_blank');
			this.show = true;
			this.fileUrl = url;
		},

		// 设置查询时间，只查询近一年的订单
		setSearchDate() {
			let now = Date.now();
			this.start = this.$base.getDate(now - 365 * 24 * 60 * 60 * 1000);
			this.end = this.$base.getDate(now + 24 * 60 * 60 * 1000);
		},

		// 查询数据
		search() {
			this.currentPage++;
			let send = {
				limit: 10,
				page: this.currentPage,
				startTime: this.start,
				endTime: this.end,
				assureInfo: '',
				payState: '',
				userId: '',
			};
			this.loading = true;
			http_getBills(send)
				.then(res => {
					this.total = res.total;
					let list = res.rows.map(item => {
						item.assuredList = String(item.assureInfo)
							.split(',')
							.map(as => {
								let asList = as.split('&');
								return { name: asList[0], mobile: asList.length > 1 ? asList[1] : '' };
							});
						return item;
					});
					this.list.push(...list);
					console.log(this.list);

					if (res.total == 0) {
						this.finished = true;
					}
				})
				.catch(err => {
					this.finished = true;
					if (err.message === '用户未登录') {
						this.$router.push({
							path: 'login',
							query: {
								pid: this.$route.query.pid,
							},
						});
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},

		onLoad() {
			if ((!this.list.length && !this.finished) || this.list.length < this.total) {
				this.search();
			} else {
				this.finished = true;
			}
		},

		// 被保险人名称格式化
		assuredNameFormatter(info) {
			if (info.assureNum <= this.showNameNum) {
				return info.assuredList.map(item => item.name).join(',');
			} else {
				return info.assuredList
					.slice(0, this.showNameNum)
					.map(item => item.name)
					.join(',');
			}
		},

		// 显示所有被保险人
		showAllAssured(info) {
			this.assuredList = info.assuredList;
			this.assuredDialog = true;
		},
	},
};
</script>
